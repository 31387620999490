body {
  // radio
  .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-focused .ant-radio-inner {
    border-color: @BRAND_COLOR;
  }
  .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-focused .ant-radio-inner {
    border-color: @BRAND_COLOR;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: @BRAND_COLOR;
  }
  .ant-radio-inner:after {
    background: @btn-primary-bg;
  }

  // radio button
  :not(.ant-radio-button-wrapper-disabled) {
    &.ant-radio-button-wrapper:hover,
    &.ant-radio-button-wrapper-focused {
      color: @TEXT_DEFAULT_COLOR;
    }

    &.ant-radio-button-wrapper-checked,
    &.ant-radio-button-wrapper-checked:hover {
      color: @TEXT_DEFAULT_COLOR;
      border-color: @BRAND_COLOR;
      box-shadow: -1px 0 0 0 @BRAND_COLOR;
    }

    &.ant-radio-button-wrapper-checked {
      &::before {
        background-color: @BRAND_COLOR;
      }
    }
  }

  .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
    color: @btn-default-color;
  }

  // has error
  .has-error .ant-radio-button-wrapper {
    border-color: @error-color;
    &:not(:first-child)::before {
      background-color: @error-color;
    }
  }

  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: @BRAND_COLOR;
    border-color: @BRAND_COLOR;

    &:hover {
      background: @BRAND_COLOR;
      border-color: @BRAND_COLOR;
    }
  }
}
