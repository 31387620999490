@import (reference) '~antd/lib/style/themes/default.less';
@import '../../commons/variables.less';

.control-focus-n-active-mixin() {
  border-color: @BRAND_COLOR;
  box-shadow: 0 0 0 2px @BRAND_SHADOW_COLOR;
}

body {
  // input
  .ant-input:focus {
    .control-focus-n-active-mixin();
  }

  // select
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    .control-focus-n-active-mixin();
  }

  // cascader
  .ant-cascader-picker:focus .ant-cascader-input {
    .control-focus-n-active-mixin();
  }
  // calendar-picker
  .ant-calendar-picker:hover
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: @BRAND_COLOR;
  }
  .ant-calendar-picker:focus
    .ant-calendar-picker-input:not(.ant-input-disabled) {
    .control-focus-n-active-mixin();
  }
  .ant-calendar-today .ant-calendar-date {
    border-color: @BRAND_COLOR;
    color: @BRAND_COLOR;
    font-weight: normal;
  }
  .ant-calendar-selected-day .ant-calendar-date,
  .ant-calendar-date:active {
    background: @btn-primary-bg;
    color: @btn-primary-color;
  }
  .ant-calendar-selected-day .ant-calendar-date:hover {
    background: @BRAND_COLOR;
    border-color: @BRAND_COLOR;
  }

  // checkbox
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @BRAND_COLOR;
  }
  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner {
    background-color: @BRAND_COLOR;
    border-color: @BRAND_COLOR;
  }
  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: @btn-primary-color;
    border-width: 1px;
  }
}
