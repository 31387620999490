.menu {
  line-height: 53px;
  border-bottom: 0;
}

.headerContainer,
.menuContainer {
  display: flex;
  justify-content: space-between;
  max-height: 100%;
  overflow: hidden;
}

.menuContainer {
  margin-left: 90px;
  border-bottom: 0;
}

.headerLeft {
  display: flex;
  justify-content: flex-start;
}

.headerRight {
  display: flex;
  justify-content: flex-end;
}

.profileLink {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contactsBlock {
  color: white;
  margin-left: 20px;
  & a {
    color: inherit;
  }
}

.logoImg {
  width: 164px;
}

.content {
  padding: 24px 80px;
}

.subMenuArrowIcon {
  padding-left: 7px;
}

@layout-header-height: 55px;@layout-header-background: #fff;@primary-color: #1D68A3;@item-hover-bg: rgba(29, 104, 163, 0.05);@item-active-bg: rgba(29, 104, 163, 0.05);@table-row-hover-bg: rgba(29, 104, 163, 0.05);@layout-body-background: #fff;@font-family: Museo Sans, sans-serif;@tag-default-bg: transparent;@input-addon-bg: transparent;@btn-primary-bg: #7D5CE4;@btn-primary-color: #ffffff;@btn-default-color: #646464;@input-hover-border-color : #7D5CE4;@link-hover-color: #247FC6;@link-active-color: #175789;@text-color: #333;@form-item-margin-bottom: 16px;