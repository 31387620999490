@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  font-style: Medium;
  src: url('/static/fonts/general/museosanscyrl-500-webfont.woff2')
      format('woff2'),
    url('/static/fonts/general/museosanscyrl-500-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  font-style: Black;
  src: url('/static/fonts/general/museosanscyrl-900-webfont.woff2')
      format('woff2'),
    url('/static/fonts/general/museosanscyrl-900-webfont.woff') format('woff');
}

@font-face {
  font-family: 'rouble';
  font-weight: 400;
  font-style: Normal;
  src: url('/static/fonts/rouble/sf-ui-display-regular-webfont.woff2')
      format('woff2'),
    url('/static/fonts/rouble/sf-ui-display-regular-webfont.woff')
      format('woff');
}

@font-face {
  font-family: 'rouble';
  font-weight: 700;
  font-style: Bold;
  src: url('/static/fonts/rouble/sf-ui-display-bold-webfont.woff2')
      format('woff2'),
    url('/static/fonts/rouble/sf-ui-display-bold-webfont.woff') format('woff');
}
