body .ant-layout-header {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.08);
  z-index: 1;
}
body .ant-menu-horizontal > .ant-menu-item:hover,
body .ant-menu-horizontal > .ant-menu-submenu:hover,
body .ant-menu-horizontal > .ant-menu-item-active,
body .ant-menu-horizontal > .ant-menu-submenu-active,
body .ant-menu-horizontal > .ant-menu-item-open,
body .ant-menu-horizontal > .ant-menu-submenu-open,
body .ant-menu-horizontal > .ant-menu-item-selected,
body .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #333;
  border-color: #7d5ce4;
}
body .ant-menu-item > a,
body .ant-menu-horizontal > .ant-menu-item > a {
  color: inherit;
  transition: none;
}
body .ant-menu-item > a:hover,
body .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: inherit;
}
body .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: inherit;
}
body .ant-menu-item:hover,
body .ant-menu-item-active,
body .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
body .ant-menu-submenu-active,
body .ant-menu-submenu-title:hover {
  color: #333;
}
body .ant-btn:hover,
body .ant-btn:focus {
  color: #646464;
  border-color: #7d5ce4;
}
body .ant-btn:active,
body .ant-btn.active {
  color: #646464;
  border-color: #6d4fcb;
}
body .ant-btn-primary:hover,
body .ant-btn-primary:focus {
  color: #ffffff;
  background-color: #8e6aff;
  border-color: #8e6aff;
}
body .ant-btn-primary:active,
body .ant-btn-primary.active {
  background-color: #6d4fcb;
  border-color: #6d4fcb;
}
body .ant-btn-primary.disabled,
body .ant-btn-primary[disabled],
body .ant-btn-primary.disabled:hover,
body .ant-btn-primary[disabled]:hover,
body .ant-btn-primary.disabled:focus,
body .ant-btn-primary[disabled]:focus,
body .ant-btn-primary.disabled:active,
body .ant-btn-primary[disabled]:active,
body .ant-btn-primary.disabled.active,
body .ant-btn-primary[disabled].active {
  color: #ffffff;
  background-color: #ad93ff;
  border-color: #ad93ff;
}
body .ant-input:focus {
  border-color: #7d5ce4;
  box-shadow: 0 0 0 2px rgba(125, 92, 228, 0.25);
}
body .ant-select-focused .ant-select-selection,
body .ant-select-selection:focus,
body .ant-select-selection:active {
  border-color: #7d5ce4;
  box-shadow: 0 0 0 2px rgba(125, 92, 228, 0.25);
}
body .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #7d5ce4;
  box-shadow: 0 0 0 2px rgba(125, 92, 228, 0.25);
}
body .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #7d5ce4;
}
body .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #7d5ce4;
  box-shadow: 0 0 0 2px rgba(125, 92, 228, 0.25);
}
body .ant-calendar-today .ant-calendar-date {
  border-color: #7d5ce4;
  color: #7d5ce4;
  font-weight: normal;
}
body .ant-calendar-selected-day .ant-calendar-date,
body .ant-calendar-date:active {
  background: #7D5CE4;
  color: #ffffff;
}
body .ant-calendar-selected-day .ant-calendar-date:hover {
  background: #7d5ce4;
  border-color: #7d5ce4;
}
body .ant-checkbox-wrapper:hover .ant-checkbox-inner,
body .ant-checkbox:hover .ant-checkbox-inner,
body .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #7d5ce4;
}
body .ant-checkbox-checked .ant-checkbox-inner,
body .ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #7d5ce4;
  border-color: #7d5ce4;
}
body .ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #ffffff;
  border-width: 1px;
}
body .ant-form-item-with-help {
  margin-bottom: 0;
}
body .ant-form-explain {
  font-size: 12px;
}
body .ant-form-item-label {
  text-align: left;
}
body .ant-form-item label:after {
  content: none;
}
body .ant-form-item .ant-form-item-required:before {
  content: none;
}
body .ant-form-item .ant-form-item-required::after {
  margin-right: 4px;
  content: '*';
  display: inline-block;
  line-height: 1;
  color: #f5222d;
}
body .form-item-label-vertical .ant-form-item-label {
  line-height: 20px;
}
body .ant-pagination-prev:focus .ant-pagination-item-link,
body .ant-pagination-next:focus .ant-pagination-item-link,
body .ant-pagination-prev:hover .ant-pagination-item-link,
body .ant-pagination-next:hover .ant-pagination-item-link {
  border-color: #7d5ce4;
  color: #7d5ce4;
}
body .ant-pagination-item:focus,
body .ant-pagination-item:hover {
  border-color: #7d5ce4;
}
body .ant-pagination-item:focus a,
body .ant-pagination-item:hover a {
  color: #7d5ce4;
}
body .ant-pagination-item-active {
  border-color: #7d5ce4;
}
body .ant-pagination-item-active a {
  color: #7d5ce4;
}
body .ant-pagination-jump-prev:focus:after,
body .ant-pagination-jump-next:focus:after,
body .ant-pagination-jump-prev:hover:after,
body .ant-pagination-jump-next:hover:after {
  color: #ffffff;
}
body .ant-modal-title {
  font-size: 24px;
}
body .ant-slider:hover .ant-slider-track {
  background-color: #8e6aff;
}
body .ant-slider-track {
  background-color: #7d5ce4;
}
body .ant-slider-handle {
  border: 1px solid #d9d9d9;
}
body .ant-slider:hover .ant-slider-handle,
body .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #8e6aff;
}
body .ant-spin-dot i {
  background-color: #7d5ce4;
}
body .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
body .ant-radio:hover .ant-radio-inner,
body .ant-radio-focused .ant-radio-inner {
  border-color: #7d5ce4;
}
body .ant-radio-wrapper:hover .ant-radio .ant-radio-inner,
body .ant-radio:hover .ant-radio-inner,
body .ant-radio-focused .ant-radio-inner {
  border-color: #7d5ce4;
}
body .ant-radio-checked .ant-radio-inner {
  border-color: #7d5ce4;
}
body .ant-radio-inner:after {
  background: #7D5CE4;
}
body :not(.ant-radio-button-wrapper-disabled).ant-radio-button-wrapper:hover,
body :not(.ant-radio-button-wrapper-disabled).ant-radio-button-wrapper-focused {
  color: #333;
}
body :not(.ant-radio-button-wrapper-disabled).ant-radio-button-wrapper-checked,
body :not(.ant-radio-button-wrapper-disabled).ant-radio-button-wrapper-checked:hover {
  color: #333;
  border-color: #7d5ce4;
  box-shadow: -1px 0 0 0 #7d5ce4;
}
body :not(.ant-radio-button-wrapper-disabled).ant-radio-button-wrapper-checked::before {
  background-color: #7d5ce4;
}
body .ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #646464;
}
body .has-error .ant-radio-button-wrapper {
  border-color: #f5222d;
}
body .has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #f5222d;
}
body .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #7d5ce4;
  border-color: #7d5ce4;
}
body .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #7d5ce4;
  border-color: #7d5ce4;
}
body .ant-list-header {
  padding-top: 0;
  padding-bottom: 0;
}
body .anticon-close-circle {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
}
body .anticon-close-circle:hover {
  color: #999;
}
body .anticon-close-circle:active {
  color: #666;
}
body .ant-list-item {
  overflow: hidden;
}
body .ant-input.transparent {
  border-color: transparent;
  resize: none;
  background-color: transparent;
  color: inherit;
}
body .ant-input.transparent:focus {
  box-shadow: none;
}
body .ant-input-disabled:hover {
  border-color: #d9d9d9;
}
body .ant-notification-notice-message .ant-notification-notice-message-single-line-auto-margin {
  display: none;
}
body .ant-table-thead > tr > th {
  background: transparent;
  color: rgba(0, 0, 0, 0.45);
}
body tr.ant-table-expanded-row:hover > td {
  background: transparent;
}
body .ant-tag {
  margin-right: 10px;
}
body ::selection {
  background: rgba(0, 0, 0, 0.1);
  color: inherit;
}
:root body .ant-upload-list-item .anticon-cross {
  font-size: 16px;
}
