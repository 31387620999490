body {
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: @BRAND_COLOR;
    color: @BRAND_COLOR;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: @BRAND_COLOR;
  }
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: @BRAND_COLOR;
  }
  .ant-pagination-item-active {
    border-color: @BRAND_COLOR;
  }
  .ant-pagination-item-active a {
    color: @BRAND_COLOR;
  }
  .ant-pagination-jump-prev:focus:after,
  .ant-pagination-jump-next:focus:after,
  .ant-pagination-jump-prev:hover:after,
  .ant-pagination-jump-next:hover:after {
    color: @btn-primary-color;
  }
}
