@import (reference) '~antd/lib/style/themes/default.less';
@import './variables.less';

.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.formItemLabel {
  color: @label-color;

  &::after {
    content: ':';
    margin: 0 8px 0 2px;
  }
}

hr.default {
  margin: 0;
}

.pageHeaderBase {
  padding-top: 2em;
  padding-bottom: 1em;
}

.hint {
  color: @text-color-secondary;
}

.error {
  color: @error-color;
}

.link {
  touch-action: manipulation;
  color: #1d68a3;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: #247fc6;
  }
}

// Button group
.btn-group--small {
  & > button:not(:last-child) {
    margin: 0 10px 0 0;
  }
}
.btn-group {
  & > button:not(:last-child),
  & > .ant-btn:not(:last-child) {
    margin: 0 20px 0 0;
  }
}

// Modals
.modal-base .ant-modal-body {
  padding: 0;
}

.modal-wide .ant-modal-body {
  padding: 0;
}
