@import (reference) '~antd/lib/style/themes/default.less';

// main primary color setted in ../../../atnTheme.js
body {
  // default btn style
  .ant-btn:hover,
  .ant-btn:focus {
    color: @btn-default-color;
    border-color: @BRAND_COLOR;
  }
  .ant-btn:active,
  .ant-btn.active {
    color: @btn-default-color;
    border-color: @BRAND_ACTIVE_COLOR;
  }

  // primary btn style
  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: @BTN_PRIMARY_COLOR;
    background-color: @BRAND_HOVER_COLOR;
    border-color: @BRAND_HOVER_COLOR;
  }
  .ant-btn-primary:active,
  .ant-btn-primary.active {
    background-color: @BRAND_ACTIVE_COLOR;
    border-color: @BRAND_ACTIVE_COLOR;
  }

  .ant-btn-primary.disabled,
  .ant-btn-primary[disabled],
  .ant-btn-primary.disabled:hover,
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary.disabled:focus,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary.disabled:active,
  .ant-btn-primary[disabled]:active,
  .ant-btn-primary.disabled.active,
  .ant-btn-primary[disabled].active {
    color: @BTN_PRIMARY_COLOR;
    background-color: @BRAND_DISABLE_COLOR;
    border-color: @BRAND_DISABLE_COLOR;
  }
}
