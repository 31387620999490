@import (reference) '../../commons/variables.less';

body {
  .ant-slider:hover .ant-slider-track {
    background-color: @BRAND_HOVER_COLOR;
  }
  .ant-slider-track {
    background-color: @BRAND_COLOR;
  }

  .ant-slider-handle {
    border: 1px solid @border-color-base;
  }

  .ant-slider:hover {
    .ant-slider-handle,
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: @BRAND_HOVER_COLOR;
    }
  }
}
