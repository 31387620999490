/* set base values */
/* type scale */
.textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.formItemLabel {
  color: rgba(0, 0, 0, 0.85);
}
.formItemLabel::after {
  content: ':';
  margin: 0 8px 0 2px;
}
hr.default {
  margin: 0;
}
.pageHeaderBase {
  padding-top: 2em;
  padding-bottom: 1em;
}
.hint {
  color: rgba(0, 0, 0, 0.45);
}
.error {
  color: #f5222d;
}
.link {
  touch-action: manipulation;
  color: #1d68a3;
  background-color: transparent;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
.link:hover {
  color: #247fc6;
}
.btn-group--small > button:not(:last-child) {
  margin: 0 10px 0 0;
}
.btn-group > button:not(:last-child),
.btn-group > .ant-btn:not(:last-child) {
  margin: 0 20px 0 0;
}
.modal-base .ant-modal-body {
  padding: 0;
}
.modal-wide .ant-modal-body {
  padding: 0;
}
@font-face {
  font-family: 'Museo Sans';
  font-weight: 500;
  font-style: Medium;
  src: url('/static/fonts/general/museosanscyrl-500-webfont.woff2') format('woff2'), url('/static/fonts/general/museosanscyrl-500-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Museo Sans';
  font-weight: 900;
  font-style: Black;
  src: url('/static/fonts/general/museosanscyrl-900-webfont.woff2') format('woff2'), url('/static/fonts/general/museosanscyrl-900-webfont.woff') format('woff');
}
@font-face {
  font-family: 'rouble';
  font-weight: 400;
  font-style: Normal;
  src: url('/static/fonts/rouble/sf-ui-display-regular-webfont.woff2') format('woff2'), url('/static/fonts/rouble/sf-ui-display-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'rouble';
  font-weight: 700;
  font-style: Bold;
  src: url('/static/fonts/rouble/sf-ui-display-bold-webfont.woff2') format('woff2'), url('/static/fonts/rouble/sf-ui-display-bold-webfont.woff') format('woff');
}
body {
  padding: 0;
}
#root {
  height: 100%;
}
hr {
  border-top: 0;
  border-bottom: 1px solid #cecece;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
h1 {
  font-size: 2.72em;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 1.1428571em;
}
h5 {
  font-size: 1em;
}
