body {
  .ant-form-item-with-help {
    margin-bottom: 0;
  }

  .ant-form-explain {
    font-size: @text-sm;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-form-item label {
    &:after {
      content: none;
    }
  }

  .ant-form-item .ant-form-item-required {
    &:before {
      content: none;
    }

    &::after {
      margin-right: 4px;
      content: '*';
      display: inline-block;
      line-height: 1;
      color: #f5222d;
    }
  }

  .form-item-label-vertical .ant-form-item-label {
    line-height: 20px;
  }
}
