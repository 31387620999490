@import (reference) '~antd/lib/style/themes/default.less';
@import '_header.less';
@import '_menu.less';
@import '_btn.less';
@import '_formControls.less';
@import './_forms.less';
@import './_pagination.less';
@import './_modal.less';
@import './_slider.less';
@import './_spin.less';
@import './_radio.less';
@import './_listHeader.less';

body {
  .anticon-close-circle {
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s;
  }

  .anticon-close-circle:hover {
    color: #999;
  }

  .anticon-close-circle:active {
    color: #666;
  }

  .ant-list-item {
    overflow: hidden;
  }

  .ant-input.transparent {
    border-color: transparent;
    resize: none;
    background-color: transparent;
    color: inherit;

    &:focus {
      box-shadow: none;
    }
  }

  .ant-input-disabled:hover {
    border-color: @input-border-color;
  }

  .ant-notification-notice-message
    .ant-notification-notice-message-single-line-auto-margin {
    display: none;
  }

  // Table
  .ant-table-thead > tr > th {
    background: transparent;
    color: @text-color-secondary;
  }

  tr.ant-table-expanded-row:hover > td {
    background: transparent;
  }

  // Tag
  .ant-tag {
    margin-right: 10px;
  }

  ::selection {
    background: rgba(0, 0, 0, 0.1);
    color: inherit;
  }
}

:root body {
  // Custom cross icon by design
  .ant-upload-list-item .anticon-cross {
    font-size: 16px;
  }
}
