@import (reference) '../../../styles/commons/variables.less';

body {
  .ant-menu-horizontal > .ant-menu-item:hover,
  .ant-menu-horizontal > .ant-menu-submenu:hover,
  .ant-menu-horizontal > .ant-menu-item-active,
  .ant-menu-horizontal > .ant-menu-submenu-active,
  .ant-menu-horizontal > .ant-menu-item-open,
  .ant-menu-horizontal > .ant-menu-submenu-open,
  .ant-menu-horizontal > .ant-menu-item-selected,
  .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: @TEXT_DEFAULT_COLOR;
    border-color: @BRAND_COLOR;
  }

  .ant-menu-item > a,
  .ant-menu-horizontal > .ant-menu-item > a {
    color: inherit;
    transition: none;
  }

  .ant-menu-item > a:hover,
  .ant-menu-horizontal > .ant-menu-item > a:hover {
    color: inherit;
  }

  .ant-menu-horizontal > .ant-menu-item-selected > a {
    color: inherit;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: @TEXT_DEFAULT_COLOR;
  }
}
